import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getOrderByNumber } from '../selectors';

import { closePopup } from '../actions/popup';

import { BASE_ZINDEX } from '../popup-factory';

import { OptionItemOptionRow, OptionItemCostRow } from './OptionItemDetailedSummary';
import UpgradeOrderBtn from './order/UpgradeOrderBtn';
import { getNewOrderedItemsForUpgrade } from '../helpers/order';

class UpgradePresentationPopup extends Component {

  constructor(props) {
    super(props);

    this.state = this.getStateFromProps(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.order !== prevProps.order) {
      this.setState(this.getStateFromProps(this.props));
    }
  }

  getStateFromProps(props) {
    return {
      items: props.order.items.filter(i => i.parent_type === 'OPTION' && i.hidden != 1),
      newItems : props.order.items.filter(
        i => i.parent_type !== 'OPTION' && i.parent_type !== 'PRODUCT' && i.hidden != 1
      ),
    };
  }

  handleChange(e) {
    const target = e.target;
    this.setState({newItems: this.state.newItems.filter(i => i.item_id !== target.name).concat([{ item_id: target.name, quantity: target.value }])});
  }

  render() {
    const { order, target_type, onClosePopup, capabilities, index } = this.props;
    const newItems = getNewOrderedItemsForUpgrade(this.state.newItems, order.items, true);
    const body_style = { borderTop: '1px solid #ddd' };
    const hasCapability = capability => capabilities.includes(capability);

    return (
      <div id="convert-modal" className ="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className ="row">
          <div className ="small-12 columns">
            <h3 id="modalTitle">Convert Presentation</h3>
            {target_type == 'ESTIMATE' && hasCapability('CREATE-ESTIMATE')
              ? <UpgradeOrderBtn
                  items={newItems}
                  parent_id={order.order_id}
                  target_type='UPGRADE'
                  job_id={this.props.job_id}
                  job_number={this.props.job_number}
                  fromOrderType='PRESENTATION'
                  toOrderType='ESTIMATE'
                >Convert to Estimate</UpgradeOrderBtn>
              : null}
            {target_type == 'SALES ORDER' && hasCapability('CREATE-SALES-ORDER')
              ? <UpgradeOrderBtn
                  items={newItems}
                  parent_id={order.order_id}
                  target_type='UPGRADE-SALES-ORDER'
                  job_id={this.props.job_id}
                  job_number={this.props.job_number}
                  fromOrderType='PRESENTATION'
                  toOrderType='SALES ORDER'
                >Convert to Sales Order</UpgradeOrderBtn>
              : null}
            <a className="alert button" style={{ position: 'fixed', right: 'ESTIMATE' === target_type ? '11.5rem' : '12.8rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
          </div>
        </div>
        <div className ="row popup-content">
          <div className ="small-12 columns popup-size-limit">
            <table className ="convert-products-table">
              <thead>
                <tr>
                  <th width="12%">Quantity</th>
                  <th colSpan="2">Products</th>
                  <th>Units</th>
                  <th>Margin</th>
                  <th>Amount</th>
                </tr>
              </thead>
              {this.state.items.map((item, index) => {
                const visible_options = item.options.filter(o => o.hidden != 1);
                const options = visible_options.length ?
                  visible_options.map(o =>
                    <OptionItemOptionRow key={o.item_id} index={index + 1} base_item={item} option_item={o} convert={true} handleChangeQuantity={this.handleChange} />
                  ) : [<OptionItemOptionRow key={item.item_id} index={index} base_item={item} convert={true} handleChangeQuantity={this.handleChange} />];
                return <tbody key={index} style={body_style}>
                  {options.concat(item.item_costs.map(ic =>
                    <OptionItemCostRow key={ic.item_cost_id} convert={true} base_item={item} item_cost={ic} />
                  ))}</tbody>;
              })}
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = getOrderByNumber(state, { form_number: ownProps.form_number, order_type: 'PRESENTATION' });
  return {
    order,
    capabilities: state.identity.capabilities
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
  };
};

const ConnectedUpgradePresentationPopup = connect(mapStateToProps, mapDispatchToProps)(UpgradePresentationPopup);
export default ConnectedUpgradePresentationPopup;
