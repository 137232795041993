import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createSpinnerPopup, closePopup } from '../../actions/popup';
import {
  upgradeToEstimate,
  upgradeEstimate,
  upgradeToSalesOrder,
  upgradeToInvoice,
} from '../../actions/convert';

export const UpgradeOrderBtn = React.memo(({
  items,
  parent_id,
  job_id,
  job_number,
  fromOrderType,
  toOrderType,
  target_type = 'UPGRADE',
  className = 'button',
  style = {},
  children = 'Convert'
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onUpgradeEstimateToSo = useCallback((items, parent_id, target_type) => {
    const item_ids = items.map(i => ({ item_id: i.item_id }));
    dispatch(createSpinnerPopup('Creating Sales Order...'));
    dispatch(upgradeEstimate(item_ids, parent_id, target_type, job_id, job_number))
      .then((order) => {
        navigate('/project/' + job_number + '/sales-order/' + order['form_number']);
        dispatch(closePopup());
      })
      .then(() => dispatch(closePopup()));
  }, [navigate, dispatch, job_id, job_number]);

  const onUpgradePptToEstimate = useCallback((items, parent_id, target_type) => {
    dispatch(createSpinnerPopup('Creating Estimate...'));
    return dispatch(upgradeToEstimate(items, parent_id, target_type, job_id, job_number))
      .then((order) => {
        navigate('/project/' + job_number + '/estimate/' + order['form_number']);
        dispatch(closePopup());
      })
      .then(() => dispatch(closePopup()));
  }, [navigate, dispatch, job_id, job_number]);

  const onUpgradePptToSalesOrder = useCallback((items, parent_id, target_type) => {
    dispatch(createSpinnerPopup('Creating Sales Order...'));
    return dispatch(upgradeToSalesOrder(items, parent_id, target_type, job_id, job_number))
      .then((order) => {
        navigate('/project/' + job_number + '/sales-order/' + order['form_number']);
        dispatch(closePopup());
      })
      .then(() => dispatch(closePopup()));
  }, [navigate, dispatch, job_id, job_number]);

  const onUpgradeSoToInvoice = useCallback((parent_id, target_type) => {
    dispatch(createSpinnerPopup('Creating Invoice...'));
    return dispatch(upgradeToInvoice(parent_id, target_type, job_id, job_number))
      .then((order) => {
        navigate('/project/' + job_number + '/invoice/' + order['form_number']);
        dispatch(closePopup());
      })
      .then(() => dispatch(closePopup()));
  }, [navigate, dispatch, job_id, job_number]);

  return (
    <a className={className}
      style={{ position: 'fixed', right: '1rem', top: '1rem', ...style }}
      onClick={e => {
        e.preventDefault();
        if (fromOrderType === 'PRESENTATION') {
          if (toOrderType === 'ESTIMATE') {
            onUpgradePptToEstimate(items, parent_id, target_type);
          } else if (toOrderType === 'SALES ORDER') {
            onUpgradePptToSalesOrder(items, parent_id, target_type);
          }
        } else if (fromOrderType === 'ESTIMATE') {
          if (toOrderType === 'SALES ORDER') {
            onUpgradeEstimateToSo(items, parent_id, target_type);
          }
        } else if (fromOrderType === 'SALES ORDER') {
          if (toOrderType === 'INVOICE') {
            onUpgradeSoToInvoice(parent_id, target_type);
          }
        }
      }}
    >{children}</a>
  );
});

export default UpgradeOrderBtn;
